import { useMsal } from "@azure/msal-react";

import { ButtonTertiary } from "@anwb/button";
import Grid from "@anwb/grid";
import { NavigationLogo, NavigationMinimal } from "@anwb/navigation";
import Typography from "@anwb/typography";

import { StyledPageTitle } from "../PageLayout/styles/pagelayout.styled";

import { StyledLoginButton } from "./styles/login.styled";

const Login = () => {
  const { instance } = useMsal();

  return (
    <Grid constrainedWidth>
      <Grid.Item>
        <NavigationMinimal logo={<NavigationLogo />} />
        <StyledPageTitle>
          <Typography variant="page-title">License Office</Typography>
        </StyledPageTitle>
        <StyledLoginButton>
          <ButtonTertiary
            onClick={() => {
              instance
                .loginRedirect()
                .then(() => {
                  // successfully redirected
                })
                .catch((e) => {
                  throw e;
                });
            }}
          >
            Login using Azure AD
          </ButtonTertiary>
        </StyledLoginButton>
      </Grid.Item>
    </Grid>
  );
};

export default Login;
