import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Form, { FormProps } from "@anwb/form";
import FormBlock from "@anwb/form-block";
import FormContainerFieldSet from "@anwb/form-container-field-set";
import FormFieldButton from "@anwb/form-field-button";
import FormFieldCheckbox from "@anwb/form-field-checkbox";
import FormFieldInput from "@anwb/form-field-input";

import { useAuthorizedRequest } from "../../helpers/api";
import { Program } from "../../types/program";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const SaveProgram = () => {
  const [name, setName] = useState<string>("");
  const [moodleProgramId, setMoodleProgramId] = useState<string>("");
  const [forTeachersOnly, setForTeachersOnly] = useState<boolean>(false);
  const [manageable, setManageable] = useState<boolean>(false);

  const { setRequest, isLoading } = useAuthorizedRequest<Program>();

  const { programId } = useParams();

  useEffect(() => {
    if (programId !== undefined) {
      setRequest({
        path: `/programs/${programId}`,
        onSuccess: (program) => {
          setName(program.name);
          setMoodleProgramId(program.moodleProgramId.toString(10));
          setForTeachersOnly(program.forTeachersOnly);
          setManageable(program.manageable);
        },
      });
    }
  }, [programId, setRequest]);

  const navigate = useNavigate();

  const saveProgram = (formData: HTMLFormElement) => {
    if (!formData.valid) return;

    const data: { [key: string]: string | boolean | number } = {
      name,
      moodleProgramId: parseInt(moodleProgramId, 10),
      forTeachersOnly,
      manageable,
    };

    if (programId !== undefined) {
      data.id = programId;
    }

    setRequest({
      path: "/programs",
      method: "post",
      data,
      onSuccess: () => {
        navigate("/programs");
      },
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Form onSubmit={saveProgram as FormProps["onSubmit"]}>
      <FormBlock>
        <FormContainerFieldSet>
          <FormFieldInput
            required
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            reference="name"
            name="name"
            label="Program name"
          />
        </FormContainerFieldSet>
        <FormContainerFieldSet>
          <FormFieldInput
            required
            value={moodleProgramId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setMoodleProgramId(e.target.value)}
            reference="moodleProgramId"
            name="moodleProgramId"
            label="Moodle Program ID"
          />
        </FormContainerFieldSet>
        <FormContainerFieldSet>
          <FormFieldCheckbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setForTeachersOnly(event.target.checked);
            }}
            checked={forTeachersOnly}
            reference="forTeachersOnly"
            name="forTeachersOnly"
            label="For Teachers only"
          />
        </FormContainerFieldSet>
        <FormContainerFieldSet>
          <FormFieldCheckbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setManageable(event.target.checked);
            }}
            checked={manageable}
            reference="manageable"
            name="manageable"
            label="Manageable"
          />
        </FormContainerFieldSet>
        <FormFieldButton type="submit">Save program</FormFieldButton>
      </FormBlock>
    </Form>
  );
};

export default SaveProgram;
