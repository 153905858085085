import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatDistance } from "date-fns";
import { enGB } from "date-fns/locale";

import { ButtonPrimary, ButtonTertiary } from "@anwb/button";
import Grid from "@anwb/grid";
import Pagination from "@anwb/pagination";
import Table from "@anwb/table";
import Typography from "@anwb/typography";

import { useAuthorizedRequest } from "../../../../helpers/api";
import { useStore } from "../../../../store/useStore";
import { School } from "../../../../types/school";

type SchoolTableProps = {
  schools: School[];
};

const SCHOOLS_PER_PAGE = 25;

const SchoolTable = ({ schools }: SchoolTableProps) => {
  const { setNotification, salesforceBaseURL, moodleBaseURL, setFilters, filters } = useStore(
    (state) => ({
      setNotification: state.setNotification,
      salesforceBaseURL: state.salesforceBaseURL,
      moodleBaseURL: state.moodleBaseURL,
      setFilters: state.setFilters,
      filters: state.filters,
    }),
  );
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [usePagination, setUsePagination] = useState(true);

  useEffect(() => {
    setCurrentPage(1);
  }, [schools]);

  const cleanUpRequest = useAuthorizedRequest();
  const cleanUpSchool = (brinvest: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete all users in Moodle for this school?")) {
      cleanUpRequest.setRequest({
        method: "post",
        path: `/schools/${brinvest}/clean-up`,
        onSuccess: () => {
          setNotification({
            title: "School cleaned up",
            variant: "success",
          });
        },
      });
    }
  };

  const createMoodleTenantRequest = useAuthorizedRequest();
  const createMoodleTenant = (brinvest: string) => {
    createMoodleTenantRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/create-tenant`,
      onSuccess: () => {
        setNotification({
          title: "Moodle Tenant created",
          variant: "success",
        });
      },
    });
  };

  const matchBasispoortInstellingRequest = useAuthorizedRequest<{ status: string }>();
  const matchBasispoortInstelling = (brinvest: string) => {
    matchBasispoortInstellingRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/match-basispoort-instelling`,
      onSuccess: (data) => {
        if (data.status === "error") {
          setNotification({
            title: `Basispoort Instelling to match was not found.`,
            variant: "warning",
          });
        } else {
          setNotification({
            title: `Basispoort Instelling is matched.`,
            variant: "success",
          });
        }
      },
    });
  };

  const synchronizeSchoolRequest = useAuthorizedRequest();
  const synchronizeSchool = (brinvest: string) => {
    synchronizeSchoolRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/synchronize`,
      onSuccess: () => {
        setNotification({
          title: `School has been synchronized.`,
          variant: "success",
        });
      },
    });
  };

  const allocateUsersOnSchoolToTenantRequest = useAuthorizedRequest();
  const allocateUsersOnSchoolToTenant = (brinvest: string) => {
    allocateUsersOnSchoolToTenantRequest.setRequest({
      method: "post",
      path: `/schools/${brinvest}/allocate-users-to-tenant`,
      onSuccess: () => {
        setNotification({
          title: `Users on school have been allocated to tenant.`,
          variant: "success",
        });
      },
    });
  };

  const listUsers = (brinvest: string) => {
    setFilters({ ...filters, ...{ brinvest } });
    navigate(`/users`);
  };

  const schoolCount = schools.length;
  const firstSchoolIndex = usePagination ? (currentPage - 1) * SCHOOLS_PER_PAGE : 0;
  const lastSchoolIndex = usePagination
    ? Math.min(schoolCount, currentPage * SCHOOLS_PER_PAGE)
    : schoolCount;

  return (
    <>
      <Typography variant="number">
        Found {schoolCount} school{schoolCount !== 1 ? "s" : ""}
        {schoolCount > 0 && (
          <>
            , showing {firstSchoolIndex + 1} to {lastSchoolIndex}
            {schoolCount > SCHOOLS_PER_PAGE && (
              <>
                &nbsp;
                <ButtonTertiary onClick={() => setUsePagination(!usePagination)}>
                  ({usePagination ? "Show all" : `Show ${SCHOOLS_PER_PAGE}`})
                </ButtonTertiary>
              </>
            )}
          </>
        )}
      </Typography>
      <Table textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderColumn>Brinvest</Table.HeaderColumn>
            <Table.HeaderColumn>Basispoort ID</Table.HeaderColumn>
            <Table.HeaderColumn>Last Synced</Table.HeaderColumn>
            <Table.HeaderColumn>ICT Coordinator Last Notified</Table.HeaderColumn>
            <Table.HeaderColumn>Moodle tenant ID</Table.HeaderColumn>
            <Table.HeaderColumn>Details</Table.HeaderColumn>
            <Table.HeaderColumn>Actions</Table.HeaderColumn>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {schools.slice(firstSchoolIndex, lastSchoolIndex).map((school) => {
            return (
              <Table.Row key={school.brinvest}>
                <Table.Column>{school.brinvest}</Table.Column>
                <Table.Column>
                  {school.basispoortId && (
                    <ButtonTertiary
                      onClick={() => {
                        if (school.basispoortId !== null) {
                          navigate(`/schools/basispoort-info/${school.basispoortId}`);
                        }
                      }}
                    >
                      {school.basispoortId}
                    </ButtonTertiary>
                  )}
                </Table.Column>
                <Table.Column title={school.lastSyncedDate ?? ""}>
                  {school.lastSyncedDate &&
                    formatDistance(new Date(school.lastSyncedDate), new Date(), {
                      locale: enGB,
                      addSuffix: true,
                    })}
                </Table.Column>
                <Table.Column title={school.ictCoordinatorLastNotifiedDate ?? ""}>
                  {school.ictCoordinatorLastNotifiedDate &&
                    formatDistance(new Date(school.ictCoordinatorLastNotifiedDate), new Date(), {
                      locale: enGB,
                      addSuffix: true,
                    })}
                </Table.Column>
                <Table.Column>
                  {school.moodleTenantId && moodleBaseURL && (
                    <ButtonTertiary
                      href={`${moodleBaseURL}/admin/tool/tenant/edit.php?id=${school.moodleTenantId}`}
                    >
                      {school.moodleTenantId}
                    </ButtonTertiary>
                  )}
                </Table.Column>
                <Table.Column>
                  <Grid>
                    {salesforceBaseURL && (
                      <Grid.Item>
                        <ButtonTertiary
                          href={`${salesforceBaseURL}/lightning/r/Account/${school.salesforceId}/view`}
                          icon="sale"
                          iconPosition="before"
                        >
                          Salesforce
                        </ButtonTertiary>
                      </Grid.Item>
                    )}
                    {school.basispoortId && (
                      <Grid.Item>
                        <ButtonTertiary
                          onClick={() => navigate(`/schools/${school.brinvest}/instelling`)}
                          icon="information"
                          iconPosition="before"
                        >
                          Basispoort
                        </ButtonTertiary>
                      </Grid.Item>
                    )}
                    <Grid.Item>
                      <ButtonTertiary
                        onClick={() => navigate(`/orders/${school.brinvest}`)}
                        icon="invoices"
                        iconPosition="before"
                      >
                        Orders
                      </ButtonTertiary>
                    </Grid.Item>
                    <Grid.Item>
                      <ButtonTertiary
                        onClick={() => listUsers(school.brinvest)}
                        icon="kids"
                        iconPosition="before"
                      >
                        Users
                      </ButtonTertiary>
                    </Grid.Item>
                  </Grid>
                </Table.Column>
                <Table.Column>
                  <Grid>
                    {!school.basispoortId && (
                      <Grid.Item>
                        <ButtonPrimary
                          onClick={() => matchBasispoortInstelling(school.brinvest)}
                          icon="inline-fold"
                          iconPosition="before"
                        >
                          Match Basispoort Instelling
                        </ButtonPrimary>
                      </Grid.Item>
                    )}
                    {school.moodleTenantId === null && (
                      <Grid.Item>
                        <ButtonPrimary
                          onClick={() => createMoodleTenant(school.brinvest)}
                          icon="home"
                          iconPosition="before"
                        >
                          Create tenant
                        </ButtonPrimary>
                      </Grid.Item>
                    )}
                    <Grid.Item>
                      <ButtonPrimary
                        onClick={() => cleanUpSchool(school.brinvest)}
                        icon="warning"
                        iconPosition="before"
                      >
                        Clean up
                      </ButtonPrimary>
                    </Grid.Item>
                    <Grid.Item>
                      <ButtonPrimary
                        onClick={() => synchronizeSchool(school.brinvest)}
                        icon="refresh"
                        iconPosition="before"
                      >
                        Synchronize
                      </ButtonPrimary>
                    </Grid.Item>
                    <Grid.Item>
                      <ButtonPrimary
                        onClick={() => allocateUsersOnSchoolToTenant(school.brinvest)}
                        icon="kids"
                        iconPosition="before"
                      >
                        Add to tenant
                      </ButtonPrimary>
                    </Grid.Item>
                  </Grid>
                </Table.Column>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {usePagination && schoolCount > SCHOOLS_PER_PAGE && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(schoolCount / SCHOOLS_PER_PAGE)}
          onPaginationChange={(number) => setCurrentPage(number)}
        />
      )}
    </>
  );
};

export default SchoolTable;
