import { useEffect, useState } from "react";

import Grid from "@anwb/grid";

import FilterQueryBuilder from "../../helpers/FilterQueryBuilder";
import { useAuthorizedRequest } from "../../helpers/api";
import { useStore } from "../../store/useStore";
import { FilterConfiguration, FilterKeyword, FilterType } from "../../types/filters";
import { School } from "../../types/school";
import Filters from "../Filters/Filters";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import SchoolTable from "./Tables/SchoolTable/SchoolTable";

const filtersForCurrentView: FilterConfiguration[] = [
  {
    type: FilterType.OptionalBoolean,
    keyword: FilterKeyword.IsOpenOnBasispoort,
    label: "Open on Basispoort",
  },
];

const Schools = () => {
  const [schools, setSchools] = useState<School[]>([]);
  const { storeFilters } = useStore((state) => ({
    storeFilters: state.filters,
  }));
  const { isLoading, setRequest } = useAuthorizedRequest<School[]>();

  useEffect(() => {
    setRequest({
      path: `/schools${new FilterQueryBuilder(
        storeFilters,
        filtersForCurrentView,
      ).getUrlSearchParamString()}`,
      onSuccess: (data) => {
        setSchools(data);
      },
    });
  }, [setRequest, storeFilters]);

  return (
    <Grid columns={1}>
      <Grid.Item>
        <Filters filters={filtersForCurrentView} />
      </Grid.Item>
      <Grid.Item>{isLoading ? <LoadingSpinner /> : <SchoolTable schools={schools} />}</Grid.Item>
    </Grid>
  );
};

export default Schools;
