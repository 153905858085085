import { ChangeEvent } from "react";

import FormFieldInput from "@anwb/form-field-input";

import { useStore } from "../../store/useStore";
import { TextFilterConfiguration } from "../../types/filters";

const TextFilter = ({ filter }: { filter: TextFilterConfiguration }) => {
  const { filters, setFilters } = useStore((state) => ({
    filters: state.filters,
    setFilters: state.setFilters,
  }));

  const value = filters[filter.keyword] ?? "none";

  const setValue = (newValue: string) => {
    setFilters({ ...filters, ...{ [filter.keyword]: newValue } });
  };

  return (
    <FormFieldInput
      onBlur={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value.trim() || "none");
      }}
      label={filter.label}
      required
      name={filter.keyword}
      reference={filter.keyword}
      value={value === "none" ? "" : value}
    />
  );
};

export default TextFilter;
