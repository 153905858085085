import { useNavigate } from "react-router-dom";

import { ButtonTertiary } from "@anwb/button";
import Table from "@anwb/table";

import { Product } from "../../types/product";

type ProductTableProps = {
  products: Product[];
};

const ProductTable = ({ products }: ProductTableProps) => {
  const navigate = useNavigate();

  return (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Name</Table.HeaderColumn>
          <Table.HeaderColumn>Details</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {products?.map((product) => {
          return (
            <Table.Row key={product.name}>
              <Table.Column>{product.name}</Table.Column>
              <Table.Column>
                <ButtonTertiary onClick={() => navigate(`/programs/${product.name}`)}>
                  List Programs
                </ButtonTertiary>
              </Table.Column>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default ProductTable;
