import { useNavigate } from "react-router-dom";

import { format, formatDistance } from "date-fns";
import { enGB } from "date-fns/locale";

import { ButtonPrimary, ButtonTertiary } from "@anwb/button";
import Grid from "@anwb/grid";
import Table from "@anwb/table";
import Typography from "@anwb/typography";

import { useAuthorizedRequest } from "../../../helpers/api";
import { useStore } from "../../../store/useStore";
import { Order, Status } from "../../../types/order";

type OrderTableProps = {
  orders: Order[];
};

function OrderTable({ orders }: OrderTableProps) {
  const { setNotification, salesforceBaseURL } = useStore((state) => ({
    setNotification: state.setNotification,
    salesforceBaseURL: state.salesforceBaseURL,
  }));

  const navigate = useNavigate();

  const activateOrderRequest = useAuthorizedRequest<{ status: string }>();
  const activateOrder = (orderId: string) => {
    activateOrderRequest.setRequest({
      method: "post",
      path: `/orders/${orderId}/activate`,
      onSuccess: (response) => {
        setNotification({
          title:
            response.status === "success"
              ? "Order successfully activated"
              : "Order could not be activated",
          children: response.status === "success" ? null : "School is not open on Basispoort",
          variant: "success",
        });
      },
    });
  };

  const deactivateOrderRequest = useAuthorizedRequest();
  const deactivateOrder = (orderId: string) => {
    deactivateOrderRequest.setRequest({
      method: "post",
      path: `/orders/${orderId}/deactivate`,
      onSuccess: () => {
        setNotification({
          title: "Order successfully deactivated",
          variant: "success",
        });
      },
    });
  };

  const updateStudentCountRequest = useAuthorizedRequest<{ status: string }>();
  const updateStudentCount = (orderId: string) => {
    updateStudentCountRequest.setRequest({
      method: "post",
      path: `/orders/${orderId}/update-student-count`,
      onSuccess: () => {
        setNotification({
          title: "Number of students successfully updated in Salesforce",
          variant: "success",
        });
      },
      onAxiosError: () => {
        setNotification({
          title: "Could not update number of students in Salesforce",
          variant: "error",
        });
      },
    });
  };

  return (
    <>
      <Typography variant="number">
        {orders.length} order{orders.length !== 1 ? "s" : ""} found
      </Typography>
      <Table textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderColumn>Order ID</Table.HeaderColumn>
            <Table.HeaderColumn>School brinvest</Table.HeaderColumn>
            <Table.HeaderColumn>Product</Table.HeaderColumn>
            <Table.HeaderColumn>Status</Table.HeaderColumn>
            <Table.HeaderColumn>Error Message</Table.HeaderColumn>
            <Table.HeaderColumn>Start date</Table.HeaderColumn>
            <Table.HeaderColumn>End date</Table.HeaderColumn>
            <Table.HeaderColumn>Last modification</Table.HeaderColumn>
            <Table.HeaderColumn>Details</Table.HeaderColumn>
            <Table.HeaderColumn>Actions</Table.HeaderColumn>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orders?.map((order) => {
            return (
              <Table.Row key={order.id}>
                <Table.Column>...{order.id.substring(10)}</Table.Column>
                <Table.Column>
                  <ButtonTertiary onClick={() => navigate(`/schools/${order.school.brinvest}`)}>
                    {order.school.brinvest}
                  </ButtonTertiary>
                </Table.Column>
                <Table.Column>
                  <ButtonTertiary onClick={() => navigate(`/programs/${order.product.name}`)}>
                    {order.product.name}
                  </ButtonTertiary>
                </Table.Column>
                <Table.Column>{order.status}</Table.Column>
                <Table.Column>{order.errorMessage}</Table.Column>
                <Table.Column>{format(new Date(order.startDate), "PP")}</Table.Column>
                <Table.Column>{format(new Date(order.endDate), "PP")}</Table.Column>
                <Table.Column title={order.salesforceLastModifiedDate.toString()}>
                  {formatDistance(new Date(order.salesforceLastModifiedDate), new Date(), {
                    locale: enGB,
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </Table.Column>
                <Table.Column>
                  <Grid>
                    {salesforceBaseURL !== undefined && (
                      <Grid.Item>
                        <ButtonTertiary
                          href={`${salesforceBaseURL}/lightning/r/Activiteit__c/${order.id}/view`}
                          icon="sale"
                          iconPosition="before"
                        >
                          Salesforce
                        </ButtonTertiary>
                      </Grid.Item>
                    )}
                  </Grid>
                </Table.Column>
                <Table.Column>
                  {[Status.Actief, Status.Definitief].includes(order.status) && (
                    <ButtonPrimary
                      onClick={() => activateOrder(order.id)}
                      icon={order.status === Status.Actief ? "refresh" : "success"}
                      iconPosition="before"
                    >
                      {order.status === Status.Actief ? "Reactivate" : "Activate"}
                    </ButtonPrimary>
                  )}
                  {[Status.Actief].includes(order.status) && (
                    <ButtonPrimary
                      onClick={() => deactivateOrder(order.id)}
                      icon="error"
                      iconPosition="before"
                    >
                      Deactivate
                    </ButtonPrimary>
                  )}
                  <ButtonPrimary
                    onClick={() => updateStudentCount(order.id)}
                    iconPosition="before"
                    icon="refresh"
                    title="Update student count in Salesforce"
                  >
                    Student&nbsp;count
                  </ButtonPrimary>
                </Table.Column>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

export default OrderTable;
