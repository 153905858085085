import { create } from "zustand";

import { NotificationProps } from "@anwb/notification";

import { StoreFilters } from "../types/filters";

type Store = {
  notification: NotificationProps | undefined;
  setNotification: (notification: NotificationProps | undefined) => void;
  salesforceBaseURL: string | undefined;
  setSalesforceBaseURL: (salesforceBaseURL: string) => void;
  moodleBaseURL: string | undefined;
  setMoodleBaseURL: (moodleBaseURL: string) => void;
  filters: StoreFilters;
  setFilters: (filters: StoreFilters) => void;
};

const getCurrentSchoolyearStartDate = () => {
  const today = new Date();
  if (today.getMonth() < 7) {
    return `${today.getFullYear() - 1}-08-01`;
  }
  return `${today.getFullYear()}-08-01`;
};

export const useStore = create<Store>((set) => ({
  notification: undefined,
  setNotification: (notification: NotificationProps | undefined) => set({ notification }),
  salesforceBaseURL: undefined,
  setSalesforceBaseURL: (salesforceBaseURL: string) => set({ salesforceBaseURL }),
  moodleBaseURL: undefined,
  setMoodleBaseURL: (moodleBaseURL: string) => set({ moodleBaseURL }),
  filters: {
    "suspended-in-moodle": "none",
    "is-open-on-basispoort": "true",
    status: "Definitief",
    "product-code": "none",
    "start-date": getCurrentSchoolyearStartDate(),
    "exists-in-moodle": "none",
    "has-basispoort-license-for-streetwise": "none",
    "has-basispoort-license-for-license-management": "none",
    brinvest: "none",
    jaargroep: "none",
  },
  setFilters: (filters: StoreFilters) => set({ filters }),
}));
