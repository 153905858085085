import { Route, Routes } from "react-router-dom";

import OrderPerSchool from "../OrderPerSchool/OrderPerSchool";
import Orders from "../Orders";

const OrderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Orders />} />
      <Route path="/:brinvest" element={<OrderPerSchool />} />
    </Routes>
  );
};

export default OrderRoutes;
