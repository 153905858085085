import { Route, Routes } from "react-router-dom";

import BasispoortDetails from "../BasispoortDetails/BasispoortDetails";
import BasispoortInstelling from "../BasispoortInstelling/BasispoortInstelling";
import Schools from "../Schools";
import SingleSchool from "../SingleSchool/SingleSchool";

const SchoolRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Schools />} />
      <Route path="/:schoolId" element={<SingleSchool />} />
      <Route path="/basispoort-info/:basispoortId" element={<BasispoortDetails />} />
      <Route path="/:brinvest/instelling" element={<BasispoortInstelling />} />
    </Routes>
  );
};

export default SchoolRoutes;
