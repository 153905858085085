import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonTertiary } from "@anwb/button";
import Form from "@anwb/form";
import FormBlock from "@anwb/form-block";
import FormContainerFieldSet from "@anwb/form-container-field-set";
import FormFieldButton from "@anwb/form-field-button";
import FormFieldInput from "@anwb/form-field-input";
import Grid from "@anwb/grid";
import Table from "@anwb/table";

import { useAuthorizedRequest } from "../../helpers/api";
import { IBasispoortInstellingModel } from "../../types/basispoortDetails";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const searchFields = ["naam", "brincode", "adres", "plaatsnaam", "bestuurscode"] as const;
type ValidProps = (typeof searchFields)[number];
type NAWSearchQueryParams = Record<ValidProps, string>;

const defaultSearchValues: NAWSearchQueryParams = searchFields.reduce(
  (r, key): NAWSearchQueryParams => {
    return {
      ...r,
      [key]: "",
    };
  },
  {} as NAWSearchQueryParams,
);

const BasispoortSearch = () => {
  const [instellingen, setInstellingen] = useState<IBasispoortInstellingModel[] | undefined>(
    undefined,
  );
  const [searchValues, setSearchValues] = useState<NAWSearchQueryParams>(defaultSearchValues);

  const navigate = useNavigate();

  const SearchField = ({ keyword, label }: { keyword: ValidProps; label: string }) => {
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      searchValues[keyword] = evt.target.value.trim();
      setSearchValues(searchValues);
    };
    return (
      <FormFieldInput
        reference={keyword}
        name={keyword}
        label={label}
        value={searchValues[keyword]}
        onChange={onChange}
      />
    );
  };

  const doSearchRequest = useAuthorizedRequest<IBasispoortInstellingModel[]>();
  const doSearch = () => {
    setInstellingen(undefined);

    const nonEmptySearchValues = Object.entries(searchValues)
      .filter(([, value]) => {
        return value !== "";
      })
      .reduce((r, [key, value]) => {
        return {
          ...r,
          [key]: value,
        };
      }, {});

    doSearchRequest.setRequest({
      path: `/basispoort/nawsearch?${new URLSearchParams(nonEmptySearchValues).toString()}`,
      onSuccess: (data) => {
        setInstellingen(data);
      },
    });
  };

  return (
    <Grid>
      <Grid.Item>
        <Form onSubmit={() => doSearch()}>
          <FormBlock>
            <FormContainerFieldSet>
              <SearchField keyword="naam" label="Naam" />
              <SearchField keyword="brincode" label="BRIN code" />
            </FormContainerFieldSet>
            <FormContainerFieldSet>
              <SearchField keyword="adres" label="Adres" />
              <SearchField keyword="plaatsnaam" label="Plaatsnaam" />
            </FormContainerFieldSet>
            <FormContainerFieldSet>
              <SearchField keyword="bestuurscode" label="Bestuurscode" />
            </FormContainerFieldSet>
            <FormFieldButton type="submit">Search</FormFieldButton>
          </FormBlock>
        </Form>
      </Grid.Item>
      <Grid.Item>
        {doSearchRequest.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderColumn>ID</Table.HeaderColumn>
                <Table.HeaderColumn>Naam</Table.HeaderColumn>
                <Table.HeaderColumn>Brincode</Table.HeaderColumn>
                <Table.HeaderColumn>Straat</Table.HeaderColumn>
                <Table.HeaderColumn>Huisnummer</Table.HeaderColumn>
                <Table.HeaderColumn>Huisnummer Toevoeging</Table.HeaderColumn>
                <Table.HeaderColumn>Postcode</Table.HeaderColumn>
                <Table.HeaderColumn>Woonplaats</Table.HeaderColumn>
                <Table.HeaderColumn>Telefoon Net</Table.HeaderColumn>
                <Table.HeaderColumn>Telefoon Abon</Table.HeaderColumn>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {instellingen?.map((instelling) => {
                return (
                  <Table.Row key={instelling.id}>
                    <Table.Column>{instelling.id}</Table.Column>
                    <Table.Column>{instelling.naam}</Table.Column>
                    <Table.Column>
                      {instelling.brincode && (
                        <ButtonTertiary
                          onClick={() => navigate(`/schools/${instelling.brincode ?? ""}`)}
                        >
                          {instelling.brincode}
                        </ButtonTertiary>
                      )}
                    </Table.Column>
                    <Table.Column>{instelling.straat}</Table.Column>
                    <Table.Column>{instelling.huisnummer}</Table.Column>
                    <Table.Column>{instelling.huisnummerToevoeging}</Table.Column>
                    <Table.Column>{instelling.postcode}</Table.Column>
                    <Table.Column>{instelling.woonplaats}</Table.Column>
                    <Table.Column>{instelling.telefoonNet}</Table.Column>
                    <Table.Column>{instelling.telefoonAbon}</Table.Column>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Grid.Item>
    </Grid>
  );
};

export default BasispoortSearch;
