import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Grid from "@anwb/grid";
import { NavigationLogo, NavigationMinimal } from "@anwb/navigation";
import Notification from "@anwb/notification";
import { SubNavigation } from "@anwb/sub-navigation";
import Typography from "@anwb/typography";

import { useAuthorizedRequest } from "../../helpers/api";
import { useStore } from "../../store/useStore";
import { Environment } from "../../types/environment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Profile from "../Profile/Profile";

import { StyledPageTitle } from "./styles/pagelayout.styled";

const PageLayout = () => {
  const { notification, setNotification, setSalesforceBaseURL, setMoodleBaseURL } = useStore(
    (state) => ({
      notification: state.notification,
      setNotification: state.setNotification,
      setSalesforceBaseURL: state.setSalesforceBaseURL,
      setMoodleBaseURL: state.setMoodleBaseURL,
    }),
  );
  const location = useLocation();
  const { isLoading, setRequest } = useAuthorizedRequest<Environment>();

  useEffect(() => {
    // clear possible notification on location change
    setNotification(undefined);
  }, [location, setNotification]);

  useEffect(() => {
    // clear possible notification on environment change
    setNotification(undefined);

    setRequest({
      path: "/environment",
      onSuccess: (data) => {
        setSalesforceBaseURL(data.salesforce);
        setMoodleBaseURL(data.moodle);
      },
    });
  }, [setRequest, setNotification, setMoodleBaseURL, setSalesforceBaseURL]);

  return (
    <Grid constrainedWidth>
      <Grid.Item>
        <NavigationMinimal logo={<NavigationLogo />} />
        <StyledPageTitle>
          <Typography variant="page-title">License Office</Typography>
          {isLoading ? <LoadingSpinner /> : <Profile />}
        </StyledPageTitle>
        <SubNavigation>
          <SubNavigation.Item href={"/schools"}>Schools</SubNavigation.Item>
          <SubNavigation.Item href={"/orders"}>Orders</SubNavigation.Item>
          <SubNavigation.Item href={"/basispoort-search"}>Basispoort search</SubNavigation.Item>
          <SubNavigation.Item href={"/demo-users"}>Demo users</SubNavigation.Item>
          <SubNavigation.Item href={"/products"}>Products</SubNavigation.Item>
          <SubNavigation.Item href={"/programs"}>Programs</SubNavigation.Item>
        </SubNavigation>
      </Grid.Item>
      {notification && (
        <Grid.Item>
          <Notification variant={notification.variant} title={notification.title} closeButton>
            {notification.children}
          </Notification>
        </Grid.Item>
      )}
      <Grid.Item>
        <Outlet />
      </Grid.Item>
    </Grid>
  );
};

export default PageLayout;
