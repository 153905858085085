import { useEffect, useState } from "react";

import Grid from "@anwb/grid";

import { useAuthorizedRequest } from "../../helpers/api";
import { Product } from "../../types/product";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import ProductTable from "./ProductTable";

const Products = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const { isLoading, setRequest } = useAuthorizedRequest<Product[]>();

  useEffect(() => {
    setRequest({
      path: `/products`,
      onSuccess: (data) => {
        setProducts(data);
      },
    });
  }, [setRequest]);

  return (
    <Grid columns={1}>
      <Grid.Item>{isLoading ? <LoadingSpinner /> : <ProductTable products={products} />}</Grid.Item>
    </Grid>
  );
};

export default Products;
