import { useAccount, useMsal } from "@azure/msal-react";

import { ButtonTertiary } from "@anwb/button";

import {
  AccountName,
  AccountUserName,
  StyledLogoutButton,
  StyledProfile,
} from "./styles/profile.styled";

const Profile = () => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  return (
    <StyledProfile>
      <div>
        <AccountName variant="label-title">{account?.name}</AccountName>
        <AccountUserName variant="support-text">{account?.username}</AccountUserName>
      </div>
      <StyledLogoutButton>
        <ButtonTertiary
          icon="sign-out"
          onClick={() => {
            instance
              .logoutRedirect({
                account,
              })
              .then(() => {
                // successfully redirected
              })
              .catch((e) => {
                throw e;
              });
          }}
        >
          Sign out
        </ButtonTertiary>
      </StyledLogoutButton>
    </StyledProfile>
  );
};

export default Profile;
