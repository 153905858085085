import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAuthorizedRequest } from "../../../helpers/api";
import { useStore } from "../../../store/useStore";
import { Order } from "../../../types/order";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import OrderTable from "../OrderTable/OrderTable";

const OrderPerSchool = () => {
  const [orders, setOrders] = useState<Order[] | undefined>(undefined);
  const { setNotification } = useStore((state) => ({
    setNotification: state.setNotification,
  }));
  const { brinvest } = useParams();

  const { isLoading, setRequest } = useAuthorizedRequest<Order[]>();
  useEffect(() => {
    if (brinvest !== undefined) {
      setRequest({
        path: `/schools/${brinvest}/orders`,
        onSuccess: (data) => {
          setOrders(data);
        },
      });
    } else {
      setNotification({
        title: "Missing brinvest",
        variant: "error",
      });
    }
  }, [setNotification, brinvest, setRequest]);

  return isLoading ? <LoadingSpinner /> : <OrderTable orders={orders || []} />;
};

export default OrderPerSchool;
