import { useEffect, useState } from "react";

import Grid from "@anwb/grid";
import Table from "@anwb/table";

import { Jaargroep } from "@licentiekantoor/shared/src/types/basispoort/Jaargroep";

import { useAuthorizedRequest } from "../../helpers/api";
import { Program } from "../../types/program";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import ProgramJaargroepIcon from "./ProgramJaargroepIcon";

const ProgramsJaargroepen = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const { setRequest, isLoading } = useAuthorizedRequest<Program[]>();

  useEffect(() => {
    setRequest({
      path: `/programs`,
      onSuccess: (data) => {
        setPrograms(data);
      },
    });
  }, [setRequest]);

  return (
    <Grid columns={1}>
      <Grid.Item>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderColumn>Program</Table.HeaderColumn>
                {Object.values(Jaargroep).map((jaargroep) => (
                  <Table.HeaderColumn key={jaargroep}>{jaargroep}</Table.HeaderColumn>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {programs.map((program) => (
                <Table.Row key={program.id}>
                  <Table.Column>{program.name}</Table.Column>
                  {Object.values(Jaargroep).map((jaargroep) => {
                    return (
                      <Table.Column key={jaargroep}>
                        <ProgramJaargroepIcon jaargroep={jaargroep} program={program} />
                      </Table.Column>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Grid.Item>
    </Grid>
  );
};

export default ProgramsJaargroepen;
