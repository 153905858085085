import Table from "@anwb/table";
import Typography from "@anwb/typography";

import { Leerling } from "@licentiekantoor/shared/src/types/basispoort/Leerling";

type LeerlingenTableProps = {
  leerlingen: Leerling[];
};

const LeerlingTable = ({ leerlingen }: LeerlingenTableProps) => {
  return leerlingen.length !== 0 ? (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Id</Table.HeaderColumn>
          <Table.HeaderColumn>Achternaam</Table.HeaderColumn>
          <Table.HeaderColumn>Voornaam</Table.HeaderColumn>
          <Table.HeaderColumn>Voorvoegsel</Table.HeaderColumn>
          <Table.HeaderColumn>Jaargroep</Table.HeaderColumn>
          <Table.HeaderColumn>Groep</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {leerlingen.map((leerling) => (
          <Table.Row key={leerling.id}>
            <Table.Column>{leerling.id}</Table.Column>
            <Table.Column>{leerling.achternaam}</Table.Column>
            <Table.Column>{leerling.voornaam}</Table.Column>
            <Table.Column>{leerling.voorvoegsel}</Table.Column>
            <Table.Column>{leerling.jaargroep}</Table.Column>
            <Table.Column>{leerling.groep}</Table.Column>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Typography variant="body-text">There are no &apos;leerlingen&apos; found.</Typography>
  );
};

export default LeerlingTable;
