import { BrowserRouter, Route, Routes } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "styled-components";

import ApplicationSizeProvider from "@anwb/providers-application";
import { ANWBDefault, Fonts } from "@anwb/theme";

import BasispoortSearch from "../BasispoortSearch/BasispoortSearch";
import Courses from "../Courses/Courses";
import DemoUsers from "../DemoUsers/DemoUsers";
import Home from "../Home/Home";
import Login from "../Login/Login";
import OrderRoutes from "../Orders/OrderRoutes/OrderRoutes";
import PageLayout from "../PageLayout/PageLayout";
import ProductRoutes from "../Products/ProductRoutes";
import ProductsPrograms from "../ProductsPrograms/ProductsPrograms";
import ProgramRoutes from "../Programs/ProgramRoutes";
import ProgramsJaargroepen from "../ProgramsJaargroepen/ProgramsJaargroepen";
import SchoolRoutes from "../Schools/SchoolRoutes/SchoolRoutes";
import Users from "../Users/Users";

import msalConfig from "./authConfiguration/msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const Application = () => (
  <ThemeProvider theme={ANWBDefault}>
    <Fonts cdnBaseUrl="https://static.anwb.nl/packages/core-ui/fonts/" />
    <ApplicationSizeProvider>
      <MsalProvider instance={msalInstance}>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route element={<PageLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/schools/*" element={<SchoolRoutes />} />
                <Route path="/orders/*" element={<OrderRoutes />} />
                <Route path="/users" element={<Users />} />
                <Route path="/courses" element={<Courses />} />
                <Route path="/basispoort-search" element={<BasispoortSearch />} />
                <Route path="/demo-users" element={<DemoUsers />} />
                <Route path="/products/*" element={<ProductRoutes />} />
                <Route path="/programs/*" element={<ProgramRoutes />} />
                <Route path="/products_programs/*" element={<ProductsPrograms />} />
                <Route path="/programs_jaargroepen/*" element={<ProgramsJaargroepen />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthenticatedTemplate>
      </MsalProvider>
    </ApplicationSizeProvider>
  </ThemeProvider>
);

export default Application;
