import styled from "styled-components";

import Typography from "@anwb/typography";

export const StyledProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLogoutButton = styled.div`
  margin-left: 1rem;
`;

export const AccountName = styled(Typography)``;

export const AccountUserName = styled(Typography)`
  margin-bottom: 0;
`;
