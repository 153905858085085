import Table from "@anwb/table";
import Typography from "@anwb/typography";

import { Groep } from "@licentiekantoor/shared/src/types/basispoort/Groep";

type GroepTableProps = {
  groepen: Groep[];
};

const GroepTable = ({ groepen }: GroepTableProps) => {
  return groepen.length !== 0 ? (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Key</Table.HeaderColumn>
          <Table.HeaderColumn>Naam</Table.HeaderColumn>
          <Table.HeaderColumn>Jaargroep</Table.HeaderColumn>
          <Table.HeaderColumn>Omschrijving</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {groepen.map((groep) => (
          <Table.Row key={groep.key}>
            <Table.Column>{groep.key}</Table.Column>
            <Table.Column>{groep.naam}</Table.Column>
            <Table.Column>{groep.jaargroep}</Table.Column>
            <Table.Column>{groep.omschrijving}</Table.Column>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Typography variant="body-text">There are no &apos;groepen&apos; found.</Typography>
  );
};

export default GroepTable;
